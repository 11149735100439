.layoutStyle{
  width: 100%;
  height:100%;
  background-color: #fff;
  overflow-y: auto;
  .contentMainStyle{
    width: 100%;
    height: auto;
    display: flex;
  }
}

.menuStyle{
  width: 20%;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: "24px 0";
  background: "#fff"
}

