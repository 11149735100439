.content {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  overflow: hidden;
  background: var(--BgColorLight);
  display: flex;
  user-select: none;
  .left {
    flex: 1;
    position: relative;

    .sphere {
      position: absolute;
      left: 30%;
      width: 80%;
      z-index: 1;
      animation: sphereAnimation 2s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .people {
      position: absolute;
      left: -50%;
      top: 20%;
      width: 60%;
      // height: 100px;
      z-index: 2;
    }

    .p-animtion {
      animation: peopleAnimation 2s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .p-other-animtion {
      animation-name: pOtherAnimation; // 动画名称
      animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
      animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
      animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
      animation-duration: 3s; // 动画完成时间
    }

    .s-animtion {
      animation: sphereAnimation 2s;
      animation-fill-mode: forwards;
      animation-timing-function: ease;
    }

    .s-other-animtion {
      animation-name: sOtherAnimation; // 动画名称
      animation-direction: alternate; // 动画在奇数次（1、3、5...）正向播放，在偶数次（2、4、6...）反向播放。
      animation-timing-function: linear; // 动画执行方式，linear：匀速；ease：先慢再快后慢；ease-in：由慢速开始；ease-out：由慢速结束；ease-in-out：由慢速开始和结束；
      animation-iteration-count: infinite; //  动画播放次数，infinite：一直播放
      animation-duration: 3s; // 动画完成时间
    }
  }

  .right {
    flex: 1;
    position: relative;
    z-index: 12;

    .top {
      width: 80%;
      margin-left: 38px;
      color: rgb(51, 52, 124);
      font-size: 20px;
      font-weight: 600;
      font-family: "Century Gothic", Times, serif;
      position: absolute;
      left: 50%;
      top: 5%;
      transform: translate(-50%, 0);

      .top-item {
        float: left;
        width: 150px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-right: 10px;
        transition: 0.5s;

        &:hover {
          border: 0;
          background-color: #fff;
          border-radius: 50px;
          cursor: pointer;
          box-shadow: -1px 1px 12px 1px rgba(182, 183, 185, 0.37);
        }
      }
    }

    .form-wrappepr {
      width: 60%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: right;

      h1 {
        float: left;
        font-family: "emoji";
        margin: 30px 0;
        font-size: 56px;
        text-align: start;
        letter-spacing: 0px;
        color: #3451b2;
        font-weight: 800;
        >p{
          color: #3c3c43;
        }
      }

      .inputs {
        display: block;
        width: 100%;
        height: 70px;
        margin: 30px 0;
        border-radius: 10px;
        border: 0;
        background-color: rgb(210 223 237);
        color: rgb(80, 82, 84);
        font-family: "Century Gothic", Times, serif;
        outline: none;
        padding: 20px;
        box-sizing: border-box;
        font-size: 20px;
      }

      .tips {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        margin-top: -15px;
        color: rgb(160, 170, 182);
        cursor: pointer;
      }

      button {
        width: 100%;
        height: 50px;
        background-color: rgb(68, 96, 241);
        border-radius: 10px;
        font-size: 15px;
        color: #fff;
        border: 0;
        font-weight: 600;
        margin-bottom: 30px;
        cursor: pointer;
        box-shadow: -11px 20px 42px 0 rgba(62, 145, 255, 0.37);
        font-family: "Century Gothic", Times, serif;
      }

      .other-login {
        .divider {
          width: 100%;
          margin: 20px 0;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .line {
            display: inline-block;
            max-width: 35%;
            width: 35%;
            flex: 1;
            height: 1px;
            background-color: rgb(162, 172, 185);
          }

          .divider-text {
            vertical-align: middle;
            margin: 0px 20px;
            // line-height: 0px;
            display: inline-block;
            width: 150px;
            color: rgb(162, 172, 185);
            white-space: normal;
          }
        }

        .other-login-wrapper {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          .other-login-item {
            width: 70px;
            font-size: 25px;
            padding: 10px;
            text-align: center;
            border-radius: 10px;
            cursor: pointer;
            font-weight: 600;
            color: rgb(51, 49, 116);
            margin: 0 10px;
            transition: 0.4s;

            img {
              width: 40px;
              height: 40px;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }

            &:hover {
              width: 80px;
              height: 50%;
              background-color: #fff;
              border: 0;
              box-shadow: -20px 10px 32px 1px rgba(182, 183, 185, 0.37);
            }
          }
        }
      }
    }
  }

  .changethemeStyle{
    position: absolute;
    right: 38px;
    top: 38px;
    width: fit-content;
    height: fit-content;
    z-index: 99;
  }
}

@keyframes movement {

  0%,
  100% {
    background-size: 130vmax 130vmax, 80vmax 80vmax, 90vmax 90vmax, 110vmax 110vmax, 90vmax 90vmax;
    background-position: -80vmax -80vmax, 60vmax -30vmax, 10vmax 10vmax, -30vmax -10vmax, 50vmax 50vmax;
  }

  25% {
    background-size: 100vmax 100vmax, 90vmax 90vmax, 100vmax 100vmax, 90vmax 90vmax, 60vmax 60vmax;
    background-position: -60vmax -90vmax, 50vmax -40vmax, 0vmax -20vmax, -40vmax -20vmax, 40vmax 60vmax;
  }

  50% {
    background-size: 80vmax 80vmax, 110vmax 110vmax, 80vmax 80vmax, 60vmax 60vmax, 80vmax 80vmax;
    background-position: -50vmax -70vmax, 40vmax -30vmax, 10vmax 0vmax, 20vmax 10vmax, 30vmax 70vmax;
  }

  75% {
    background-size: 90vmax 90vmax, 90vmax 90vmax, 100vmax 100vmax, 90vmax 90vmax, 70vmax 70vmax;
    background-position: -50vmax -40vmax, 50vmax -30vmax, 20vmax 0vmax, -10vmax 10vmax, 40vmax 60vmax;
  }
}

@keyframes sphereAnimation {
  0% {
    width: 10%;
  }

  100% {
    width: 80%;
    transform: translate(-30%, 5%);
  }
}

@keyframes peopleAnimation {
  0% {
    width: 40%;
  }

  100% {
    width: 60%;
    transform: translate(90%, -10%);
  }
}

@keyframes pOtherAnimation {
  0% {
    transform: translate(90%, -10%);
  }

  100% {
    transform: translate(90%, -15%);
  }
}

@keyframes sOtherAnimation {
  0% {
    transform: translate(-30%, 5%);
  }

  100% {
    transform: translate(-30%, 10%);
  }
}