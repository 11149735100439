/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  // width: 2px;
  // height: 2px;
  // height: 67px;
}

::scrollbar {
  width: 6px;
  height: 6px;
  // width: 2px;
  // height: 2px;
  // height: 67px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  display: none;
  // -webkit-box-shadow: inset 0 0 3px #b18933;
  // border-radius: 1px;
}

::scrollbar-track {
  display: none;
  // -webkit-box-shadow: inset 0 0 3px #98f165;
  // border-radius: 1px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #b7bfc7;
  -webkit-box-shadow: inset 0 0 1px #b7bfc7;
}

::scrollbar-thumb {
  border-radius: 10px;
  background: #b7bfc7;
  -webkit-box-shadow: inset 0 0 1px #b7bfc7;
}

.status-wrapper{
  margin: 20px auto;
  width: 315px;
  text-align: center;
  p{
    margin: 30px 0;
  }
}