.headerStyle {
  position: sticky;
  top: 0;
  left: 0;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03),
    0 1px 6px -1px rgba(0, 0, 0, 0.02),
    0 2px 4px 0 rgba(0, 0, 0, 0.02);
  backdrop-filter: blur(8px);
  overflow: hidden;
  z-index: 999;
  .main{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logo {
      width: fit-content;
      height: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: bold;
      font-size: 25px;
      gap: 10px;
      font-family: fantasy;
    }
    .right{
      display: flex;
      justify-content: space-around;
      gap:50px;
      width:fit-content;
      height: 100%;
      .iconStyle{
        min-width:80px;
        display: flex;
        gap: 30px;
        padding: 0 10px;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        .tips {
            display: flex;
            justify-content: center;
            align-items: center;
            color: rgb(160, 170, 182);
            cursor: pointer;
            &:hover {
              background-color: aliceblue;
              border-radius: 9px;
              padding: 10px;
              height: fit-content;
              height: fit-content;
            }
          }
      }
      
      // 组件样式
      .ant-tabs{
        height: 100%;
      }
      .ant-tabs-nav{
        margin: 0;
        height: 100%;
      }
    }
  }   
}

// 实现仿造element-plus
.ant-layout-header {
  // 实现仿造element-plus
  background:radial-gradient(transparent 1px, #fff 1px) 4px 4px;
  background-image: radial-gradient(transparent 1px, #fff 1px);
  background-size: 4px 4px;
  backdrop-filter: saturate(50%) blur(4px);
}


// 实现透明效果
// .ant-layout-header {
//   background:rgba(255,255,255,0.5) !important ;
// }